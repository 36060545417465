<template>
  <div class="news-container">
    <div class="news_bg">
      <div class="margin width1480 hidden">
        <div class="tit">新闻动态</div>
        <!-- <div class="sx">
          <div class="bg">
            <dd>年份 <i class="fa fa-caret-down"></i></dd>
            <dl>
              <a href="">2010</a>
              <a href="">2015</a>
              <a href="">2018</a>
              <a href="">2019</a>
              <a href="">2020</a>
              <a href="">2021</a>
              <a href="">2022</a>
            </dl>
          </div>
        </div> -->
        <ul class="news_list margin-top-32">
          <li v-for="news in state.newsList" :key="news.title">
            <a @click="jumpToNewsDetail(news.url)">
              <div class="t"><img :src="news.img" :class="news.imgClas || 'img-cover-view'"/></div>
              <div class="f">
                <dt>{{news.date}}</dt>
                <dd>{{news.title}}</dd>
                <dl>{{news.subtitle}}</dl>
                <ol>
                  阅读详细
                </ol>
              </div>
            </a>
          </li>
        </ul>
        <!-- <div class="fenye">
          <a href=""><i class="fa fa-angle-left"></i></a>
          <span>1</span>
          <a href="">2</a>
          <a href="">3</a>
          <a href="">4</a>
          <a href="">5</a>
          <a href=""><i class="fa fa-angle-right"></i></a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
import config from '../../libs/config';

export default {
  name: 'news',
  components: {},
  setup() {
    const {proxy} = getCurrentInstance()

    const state = reactive({
      newsList:[],
    })


    const jumpToNewsDetail = (newsUrl)=>{
      if(newsUrl){  
        window.open(newsUrl);
      }
    }

    onMounted(() => {
      document.title = '新闻中心'
      
      // 滚动到页面最上方
      window.scrollTo(0, 0);

      state.newsList = config.newsList;

    })

    return {
      state,
      jumpToNewsDetail
    }
  },
}
</script>

<style scoped>

.margin-top-32{
  margin-top: 32px;
}

img.set-height-img {
    height: 220px;
}

.img-cover-view{
  object-fit: cover;
}
</style>
